@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body {
    margin: 0px;
}

canvas.chart {
    height: 100%;
    min-height: 240px !important;
    max-height: 320px !important;
    width: 100%;
}

canvas {
    height: 100%;
    width: 100%;
}

.severity-CRITICAL {
    color: red;
}

.severity-MAJOR {
    color: orange;
}

.severity-MINOR {
    color: yellow;
}

.severity-WARNING {
    color: purple;
}

.severity-INTERMEDIATE {
    color: green;
}